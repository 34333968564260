import React from 'react';

export const SearchIcon = (props) => {
  const {
    strokeColor = "#FFFFFF"
  } = props;
  return (
    <svg viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor">
      <g transform="translate(1 1)" strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M13 14l8 8" />
        <circle cx="8" cy="8" r="8" />
      </g>
    </svg>
  );
}
