import React from 'react'
import './ViewMorePosts.scss'

export const ViewMorePosts = props => {
  const {
    currentItems,
    totalItems,
    showMorePostsHandler
  } = props;
  if (currentItems === totalItems) return null;
  return (
    <div className="view-more-posts">
      <div className="progress">
        <span className="progress-label">{`Viewing ${currentItems} of ${totalItems} results`}</span>
        {/* <div className="progress-bar">
          <div className="inner-progress" style={{ width: `${(parseFloat(currentItems) / parseFloat(totalItems)) * 100}%` }} />
        </div> */}
      </div>
      <button className="button styled-button" onClick={(event) => showMorePostsHandler(event)} title="Load more" aria-label="Load more">Load more</button>
    </div>
  );
}
